"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
function randomIntInSteps(a, b, step) {
    function randomInt(a, b) {
        return Math.floor(Math.random() * (b - a + 1) + a);
    }
    if (a > b) {
        // Ensure a is smaller.
        var c = a;
        a = b;
        b = c;
    }
    step = Math.abs(step);
    return a + randomInt(0, Math.floor((b - a) / step)) * step;
}
class ReactiontestCtrl {
    constructor($scope, $timeout, ModalServiceFactory, ConfigService, SoundService) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.ModalServiceFactory = ModalServiceFactory;
        this.ConfigService = ConfigService;
        this.SoundService = SoundService;
        this.rounds = [3, 5, 10, 15, 20, 30];
        this.round = JSON.parse(localStorage.getItem(`${this.constructor.name}_round`) || '5');
        this.state = 'begin';
        this.results = [];
        this.game$ = new rxjs_1.BehaviorSubject(this.state);
        this.click$ = new rxjs_1.Subject();
    }
    $onInit() {
        this.click$.pipe((0, operators_1.pairwise)(), (0, operators_1.tap)((a) => {
            if ((a[1].action == 'end') && (a[0].action == 'start')) {
                this.ms = a[1].ms - a[0].ms;
                this.results.push(this.ms);
                this.state = 'result';
            }
        })).subscribe();
        this.game$.pipe((0, operators_1.switchMap)((v) => {
            this.state = v;
            if (v == 'wait') {
                const delay = randomIntInSteps(700, 2000, 130);
                return (0, rxjs_1.timer)(delay).pipe((0, operators_1.tap)(() => {
                    this.$scope.$apply(() => {
                        this.state = 'click';
                    });
                }), (0, operators_1.switchMap)(() => {
                    return (0, rxjs_1.timer)(10000).pipe((0, operators_1.tap)(() => {
                        this.$scope.$apply(() => {
                            this.state = 'prank';
                        });
                    }), (0, operators_1.takeUntil)(this.click$));
                }));
            }
            else if (v == 'begin') {
                this.results = [];
                this.ms = undefined;
            }
            return rxjs_1.EMPTY;
        })).subscribe();
    }
    setStartClick() {
        // when green show ng-init
        this.click$.next({
            action: 'start',
            ms: (new Date()).getTime()
        });
    }
    onClick(state) {
        if ((this.state == 'wait') && (state == 'soon')) {
            this.game$.next('soon');
        }
        if ((this.state == 'soon') && (state == 'wait')) {
            this.game$.next('wait');
        }
        if ((this.state == 'prank') && (state == 'wait')) {
            this.game$.next('wait');
        }
        if ((this.state == 'begin') && (state == 'wait')) {
            this.game$.next('wait');
        }
        if ((this.state == 'click') && (state == 'click')) {
            this.click$.next({
                action: 'end',
                ms: (new Date()).getTime()
            });
        }
        if ((this.state == 'result') && (state == 'wait')) {
            if (this.results.length != this.round) {
                this.game$.next('wait');
            }
        }
        if ((this.state == 'result') && (state == 'restart')) {
            this.results = [];
            this.game$.next('wait');
        }
        if ((this.state == 'total') && (state == 'wait')) {
            this.results = [];
            this.game$.next('wait');
        }
    }
    showTotal($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.state = 'total';
    }
    setRound(round) {
        this.round = round;
        this.game$.next('begin');
        localStorage.setItem(`${this.constructor.name}_round`, JSON.stringify(round));
    }
    getAvgResults() {
        if (this.results.length)
            return Math.floor(this.results.reduce((a, b) => a + b, 0) / this.results.length);
        else
            return undefined;
    }
    minResults() {
        return this.results.length ? Math.min(...this.results) : undefined;
    }
    maxResults() {
        return this.results.length ? Math.max(...this.results) : undefined;
    }
}
ReactiontestCtrl.$inject = ['$scope', '$timeout', 'ModalServiceFactory', 'ConfigService', 'SoundService'];
const appModule = ng.module('app');
appModule.component('gameReactiontest', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: ReactiontestCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('reactiontest/');
    }]);
